<!-- If there are no errors line items, make the message bold. Zach thinks it looks better this way -->
<span [ngClass]="{'section-header': errors.length > 0}">{{ message }}</span>


<div class="error-list-container">
  <ul *ngIf="errors.length > 0">
    <li *ngFor="let error of errors">
      {{ error }}
    </li>
  </ul>
</div>

<div class="action-container center">
  <img src="assets/images/warning_img.png" alt="image" style="height: 32px; margin: 0 auto;"/>
</div>

<div *ngIf="notes.length > 0">
  <span class="section-header">NOTE:</span>
  <ul>
    <li *ngFor="let note of notes">
      {{ note }}
    </li>
  </ul>
</div>

<div class="action-container center">
  <button mat-button (click)="ok()">OK</button>
</div>